<template>
  <div class="row mt-1">
    <div class="col-12">
      <div>
        <div class="bg-light-primary rounded-top py-half px-1">
          <div class="psh-header mb-0 d-flex align-items-center px-0">
            <div class="psh-title border-0 mr-0">
              <i class="icon icon-psh-labeling text-h1" style="font-size: 2rem;" />
            </div>
            <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
              Shipping Labels
            </h4>
            <current-credit-widget v-if="hasPermission" />
            <div v-if="!isCurrentOrderShipped">
              <strong v-if="hasPermission" class="text-button d-inline" role="button" @click="openBuyLabel">
                Buy Label
              </strong>
              <strong class="text-button d-inline" role="button" @click="openUploadLabel">
                Upload Label
              </strong>
            </div>
          </div>
        </div>
        <div class="border rounded-bottom" style="margin-top: 1px">
          <div v-if="currentOrder.requestedShippingMethod">
            <div class="text-muted d-inline">
              Requested Method:
            </div>
            <div class="d-inline badge badge-light-warning py-0 my-0" style="padding-top: 2px;">
              {{ currentOrder.requestedShippingMethod }}
            </div>
          </div>
          <div class="">
            <div v-if="orderShippingLabels && orderShippingLabels.length === 0">
              <div class="text-warning p-half m-1 rounded">
                <p-icon name="bi-exclamation-triangle" class="mr-half " />
                Please use the
                <h6 v-if="hasPermission" role="button" class="p-half d-inline" @click="openBuyLabel">
                  "Buy Label" or
                </h6>
                <h6 role="button" class="p-half d-inline" @click="openUploadLabel">
                  "Upload Label"
                </h6> text buttons in the header section to purchase or upload a shipping label.
              </div>
            </div>
            <table v-else width="100%">
              <tr>
                <th class="px-half">
                  Carrier
                </th>
                <th class="px-half">
                  Source
                </th>
                <th>Tracking Number</th>
                <th v-if="currentOrder.international">
                  Declaration
                </th>
                <th>Download</th>
                <th>Status</th>
                <th>Box Items</th>
                <th v-if="!isCurrentOrderShipped">
                  Actions
                </th>
              </tr>
              <tr v-for="label in orderShippingLabels" :key="label.id" :style="{opacity: shippingLabelsArray.includes(label.id) ? 0.4 : 1}">
                <td width="52">
                  <img height="24" style="max-width: 52px;" :src="require(`@/assets/images/svg/carrier/${resolveImageName(label.carrier)}.svg`)">
                </td>
                <td width="52">
                  {{ shortenTypeName(label.shippingLabelSourceType) }}
                </td>
                <td>
                  <div class="">
                    <a v-if="label.trackingUrlProvider" :href="label.trackingUrlProvider" target="_blank">
                      {{ label.trackingNumber }}
                    </a>
                    <span v-else>{{ label.trackingNumber }}</span>
                    <copy-button :copy-value="label.trackingNumber" />
                  </div>
                </td>
                <td v-if="currentOrder.international">
                  <a v-if="label.commercialInvoiceUrl" :href="label.commercialInvoiceUrl" target="_blank">Open</a>
                </td>
                <td>
                  <a v-if="label.shippingLabelSourceType === 'AMAZON_LABEL'" @click="onDownloadFile(label)">
                    Download
                  </a>
                  <a v-else-if="label.labelUrl" :href="label.labelUrl" target="_blank" @click="changeDownloadStatus(label.id)">Download</a>
                </td>
                <td>
                  <span v-if="label.shipDate !== null" class="badge badge-light-warning">
                    Used
                  </span>
                </td>
                <td>
                  <a class="text-btn" @click.prevent="openItemSelector(label)">
                    Items
                  </a>
                </td>
                <td v-if="!isCurrentOrderShipped" width="120">
                  <a v-if="hasPermission || label.shippingLabelSourceType === 'CUSTOMER'" class="delete-btn" @click.prevent="handleDeleteLabel(label)">
                   {{ label.shippingLabelSourceType === 'PSH' ? 'Void' : 'Delete' }}
                  </a>
                </td>
              </tr>
            </table>
          </div>
          <div class="px-half mt-half mb-1">
            <div v-show="activeComponent === 'buy-label'" class="">
              <fbm-shipping-label :buy-label="buyLabelId" @emit-close="onEmitClose" />
            </div>
            <div v-show="activeComponent === 'upload-label'" class="">
              <fbm-shipping-label-url @emit-close="onEmitClose" />
            </div>
          </div>
        </div>
      </div>
      <item-selector v-if="false" :component-id="itemSelectorCompId" :box="currentShipBox" />
    </div>
  </div>
</template>

<script>
// import DepositWidget from '@/views/apps/finance/balances/DepositWidget.vue'
import CurrentCreditWidget from '@/views/apps/finance/balances/CurrentCreditWidget.vue'
import { v4 as uuidv4 } from 'uuid'
import fbmService from '@/http/requests/fbm/fbmService'
import useFbmState from '../useFbmStates'

export default {
  components: {
    'fbm-shipping-label-url': () => import('./FbmShippingLabelUrl.vue'),
    'fbm-shipping-label': () => import('./FBMShippingLabel.vue'),
    // 'deposit-widget': DepositWidget,
    'current-credit-widget': CurrentCreditWidget,
    'item-selector': () => import('./ItemSelectorForBox.vue'),
  },
  setup() {
    const {
      currentOrder, orderItems, orderShippingLabels, deleteShippingLabel, getShippingLabels, isCurrentOrderShipped, shippingLabelsArray,
    } = useFbmState()
    return {
      currentOrder,
      orderShippingLabels,
      deleteShippingLabel,
      getShippingLabels,
      isCurrentOrderShipped,
      shippingLabelsArray,
      orderItems,
    }
  },
  data() {
    return {
      activeComponent: '',
      buyLabelId: '',
      shippingLabelsObj: {},
      itemSelectorCompId: '',
      currentShipBox: {},
      boxId: '',
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    'currentOrder.id': {
      deep: true,
      immediate: true,
      handler() {
        this.activeComponent = ''
        this.orderShippingLabels.forEach(el => {
          this.shippingLabelsObj[el.id] = false
        })
      },
    },
  },
  methods: {
    async openBuyLabel() {
      this.activeComponent = 'buy-label'
      this.buyLabelId = uuidv4()
    },
    openUploadLabel() {
      this.activeComponent = 'upload-label'
    },
    onEmitClose() {
      this.activeComponent = ''
    },
    changeDownloadStatus(labelId) {
      this.shippingLabelsArray.push(labelId)
    },
    resolveImageName(payload) {
      if (payload === null || undefined) return 'other-carriers'
      const carrier = payload.toLowerCase()
      if (carrier === 'ups') return 'ups'
      if (carrier === 'fedex') return 'fedex'
      if (carrier === 'usps') return 'usps'
      if (carrier === 'dhl') return 'dhl'
      return 'other-carriers'
    },
    async handleDeleteLabel(label) {
      this.$swal({
        title: 'Are you sure you want to delete this shipping label?',
        text: `If this label is purchased on PSH, it will be made void. Tracking Number: ${label.trackingNumber}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteShippingLabel(label.id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'This shipping label has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getShippingLabels(this.currentOrder.id)
          })
        }
      })
    },
    shortenTypeName(payload) {
      return payload?.slice(0, 3).toUpperCase()
    },
    openItemSelector(labelData) {
      this.currentShipBox = []
      this.itemSelectorCompId = uuidv4()
      const items = []
      labelData.shipBox.items.forEach(item => {
        const foundItem = this.orderItems.filter(el => el.id === item.fbmOrderItemId)[0]
        items.push({ ...foundItem, shipCount: item.shipCount })
      })
      this.currentShipBox = [...items]
    },
    // onDownloadFile(label) {
    //   fbmService.getAmazonShippingLabelDocs(label.fbmOrderId, label.id)
    //     .then(response => {
    //       const fileURL = `${window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))}`
    //       const anchor = document.createElement('a')
    //       anchor.href = fileURL
    //       anchor.download = `${label.fbmOrderId}-${label.id}.pdf`
    //       anchor.click()

    //       this.shippingLabelsArray.push(label.id)
    //     })
    // },
    onDownloadFile(label) {
      fbmService.getAmazonShippingLabelDocs(label.fbmOrderId, label.id)
        .then(response => {
          const contentType = response.headers['content-type']
          const contentDisposition = response.headers['content-disposition']
          const fileParts = contentDisposition.split('filename=')[1].replaceAll('"', '').split('.')
          const fileExtension = fileParts[1]
          const fileBlob = new Blob([response.data], { type: contentType })
          const anchor = document.createElement('a')
          anchor.href = window.URL.createObjectURL(fileBlob)
          anchor.download = `${label.fbmOrderId}-${label.id}.${fileExtension}`
          anchor.click()
          this.shippingLabelsArray.push(label.id)
        })
    },

  },
}
</script>

<style lang="scss" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}
.text-button:hover {
  color: #66bcf6;
}

.delete-btn {
  margin-left: 16px;
  color: #e4388b;
  transition: color 0.3s ease;
}
.delete-btn:hover {
  color: #c56593;
}

th {
  background-color: #215271;
  color: rgb(181, 200, 227);
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1px;
}

tr {
  font-size: 13px;
}

tr, td {
  padding: 8px;
  padding-bottom: 8px;
  text-align: center;
}

tr:first-child th:first-child {
  text-align: left;
  padding-left: 6px;
}

</style>
